import React from "react";
import Home from "../Pages/Home";
import Price from "../Pages/Price";
import Company from "../Pages/Company";
import InterviewGuides from "../Pages/InterviewGuides";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivacyPolicy from "../Pages/privacy-policy";
import TermsOfService from "../Pages/terms-of-service";

const Router = () => {

    return (
        <>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/price" element={<Price />} />
                <Route exact path="/company" element={<Company />} />
                <Route exact path="/interviewGuides" element={<InterviewGuides />} />

                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="/terms-of-service" element={<TermsOfService />} />
                <Route path="*" element={<Navigate replace={true} to="/" />} />
            </Routes>
        </>
    )
}

export default Router;