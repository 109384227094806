import React from "react";

const TermsOfService = () => {
    return (
        <>
            <main className="main terms-of-service">
                <section className="legal_container__PCd_C">
                    <h1 className="legal_h1__elvz0">Terms of Service</h1>
                    <p className="legal_p__hzARZ"><span>These Terms govern the relationship between you and JobGo.</span></p>
                    <p className="legal_p__hzARZ"><span>These Terms of Service (“</span><span><strong>Terms</strong></span><span>”) govern the relationship between you and JobGo International Oy (“</span><span><strong>JobGo</strong></span><span>”) regarding your use of the JobGo platform, all of its separate applications, content, and any other services provided by JobGo related to the solution (together “</span><span><strong>Services</strong></span><span>”).</span></p>
                    <p className="legal_p__hzARZ"><span>The JobGo platform is a handy SaaS tool for job interviewing that helps you run more meaningful and insightful, high-quality job interviews.</span></p>
                    <p className="legal_p__hzARZ"><span>Subject to your acceptance of these Terms, you may access or use the Services.{/* */} </span><span><strong>These Terms affect your legal rights and obligations, so if you do not agree to the Terms, you may not use the Services.</strong></span></p>
                    <p className="legal_p__hzARZ"><span>If you are accepting these Terms on behalf of a legal entity, you represent that you have the authority to bind that legal entity to these Terms, in which case references to “you” in these Terms mean that legal entity.</span></p>
                    <p className="legal_p__hzARZ"><span>JobGo’s privacy policy (available at https://jobgo.ai/privacy-policy) (“</span><span><strong>Privacy Policy</strong></span><span>”) helps you understand what personal data and non-personal data JobGo collects and how such data is otherwise processed. Although JobGo’s Privacy Policy is not part of these Terms and creates no contractual obligations to you, it contains information that you should review prior to using the Services.</span></p>
                    <h2 className="legal_h2___qfIT">Use of Services</h2>
                    <p className="legal_p__hzARZ"><span>Subject to your compliance with these Terms, JobGo grants to you and third parties acting on behalf of you a limited, non-exclusive, non-sublicensable, revocable, and non-transferable right to access and use the Services solely for the purpose it is intended for. You may not use any part of the Services for any purpose other than the permitted use of the Services.</span></p>
                    <p className="legal_p__hzARZ"><span>You may invite users conducting interviews on your behalf to the Services by providing them with a link to the Services. You are responsible for the use of Services by your employees and other end users.</span></p>
                    <p className="legal_p__hzARZ"><span>Except as provided herein, you may not sell, transfer, rent, or give away your right to use and access the Services. You may not use the Services if you have previously been removed from using any part of the Services by JobGo.</span></p>
                    <p className="legal_p__hzARZ"><span>By using the Services, you specifically agree that you will not:</span></p>
                    <ul>
                        <li><span>Interfere with or disrupt the Services or servers or networks that provide the Services;</span></li>
                        <li><span>Attempt to decompile, reverse engineer, disassemble or hack any of the Services, or to defeat or overcome any of the encryption technologies or security measures or data transmitted, processed, or stored by JobGo; or</span></li>
                        <li><span>Circumvent technological measures designed to control access to, or elements of, the Services.</span></li>
                    </ul>
                    <p className="legal_p__hzARZ"><span>You may use the Services only in compliance with these Terms and all applicable laws and regulations.</span></p>
                    <h2 className="legal_h2___qfIT">Registration and account</h2>
                    <p className="legal_p__hzARZ"><span>Some parts or features of the Services require registration. When you register, you will create a personal user account with a username and password. You must provide us with correct personal and other information if such information is required and keep the information up-to-date.</span></p>
                    <p className="legal_p__hzARZ"><span>You must protect your username and password against misuse and keep them personal and confidential. You are responsible for maintaining the confidentiality of the information you hold for your account, including your password, and for any and all activity that occurs under your account as a result of your failing to keep this information secure and confidential. You must promptly notify us about any unauthorized access or use. You agree that you are personally responsible for the use of your user account and for all of the communication and activity in connection with the Service.</span></p>
                    <p className="legal_p__hzARZ"><span>Without limiting any other remedies, JobGo may suspend or terminate access to the Services or parts thereof if you fail to comply or if JobGo suspects such failure with the Terms or for any actual or suspected illegal or improper use of the Services.</span></p>
                    <h2 className="legal_h2___qfIT">Fees</h2>
                    <p className="legal_p__hzARZ"><span>A certain standard version of the Services is provided free of charge, while the premium version of the Service (“</span><span><strong>Premium Version</strong></span><span>”) requires payment of relevant service fees before it can be accessed.</span></p>
                    <p className="legal_p__hzARZ"><span>Premium Version can be purchased by an organization or legal entity on behalf of its users. The right to use the Premium Version is granted only to the users paid for and may be subject to other license restrictions. Once the payment for the Premium Version has been made, the right to use the Premium Version shall be in force for the time period selected and as set out in the order.</span></p>
                    <p className="legal_p__hzARZ"><span>A legally binding agreement regarding the Premium Version shall not come into force until JobGo has accepted your offer to purchase the Premium Version by means of a separate confirmation email or other written documentation from JobGo. JobGo will provide you with access to the Premium Version after we have confirmed your order by email and you have paid the applicable fees. If your access to the Premium Version is terminated, no fees will be returned, and all accrued and unpaid fees will be charged.</span></p>
                    <p className="legal_p__hzARZ"><span>JobGo has the right to change the fees at any time and at its sole discretion. JobGo will inform you in advance of the changes to the fees. By continuing the use of the Services or making new payments, you will be deemed to have accepted such a change in the fees. JobGo has the right to appoint payment service providers to receive payment of fees on behalf of JobGo.</span></p>
                    <h2 className="legal_h2___qfIT">Intellectual property rights</h2>
                    <p className="legal_p__hzARZ"><span>All rights, title, and interest in and to the Services (including the related software and media), the design of the Services and associated content including text, scripts, graphics, audio, video, interactive features and the like, and the trademarks, service marks and logos contained therein (“JobGo Material”) are owned by or licensed to JobGo, subject to copyright and other intellectual property rights under Finnish and foreign laws and international conventions. Except as expressly provided in the Terms, JobGo does not grant any express or implied rights to use JobGo Material. Any feedback you provide in relation to the Services shall be deemed to be non-confidential, and JobGo shall be free to use such feedback on an unrestricted basis.</span></p>
                    <h2 className="legal_h2___qfIT">Content</h2>
                    <p className="legal_p__hzARZ"><span>The Services may allow you to submit content to the Services. This content includes but is not limited to messages, interview questions, and other documents. When you submit content to the Services, you are solely and entirely responsible for the content you upload, transmit, store or otherwise make available through the Services, and you agree not to submit any unlawful content, content violating any intellectual property rights, content containing any viruses or harmful code or otherwise inappropriate content. This responsibility continues even if you stop using our Services. You agree to indemnify us if any third party takes action against us in relation to the content you submit.</span></p>
                    <p className="legal_p__hzARZ"><span>As between you and us, you retain all intellectual property rights and title to the content you submit to the Services. When you submit content to the Services, you grant JobGo a worldwide, perpetual, fully paid up, and unlimited license to use, store, reproduce, modify, create derivative works, distribute and otherwise utilize such content for the purpose of providing the Services, and the right to assign such rights. You represent and warrant that you have the legal right to grant these rights to JobGo; please make sure you have all the necessary rights to grant JobGo this license to the content you submit to the Service.</span></p>
                    <p className="legal_p__hzARZ"><span>You accept that JobGo may use the content provided by you, such as interview questions, to improve and develop its Services, provided any content will be anonymized or aggregated before such content is distributed or published. Additionally, JobGo may collect anonymized transaction and analytics data through the use of the Services and use such anonymized data to optimize, improve and develop the Services.</span></p>
                    <h2 className="legal_h2___qfIT">Confidentiality</h2>
                    <p className="legal_p__hzARZ"><span>Each party may be given access to Confidential Information of the other party in order to perform its obligations under this Agreement. “</span><span><strong>Confidential Information</strong></span><span>” includes all information of the other party that is either designated as confidential or should reasonably be understood to be of confidential nature. Content provided by you to the Services shall be considered Confidential Information of you, excluding anonymized or aggregated content.</span></p>
                    <p className="legal_p__hzARZ"><span>Confidential Information shall not be deemed to include information that: a) is or becomes publicly known other than through any act or omission of the receiving party; b) was in the receiving party{/* */}'{/* */}s lawful possession before the disclosure; c) is lawfully disclosed to the receiving party by a third party without restriction on disclosure; d) is independently developed by the receiving party, which independent development can be shown by written evidence; e) is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body; or f) is anonymized or aggregated content.</span></p>
                    <p className="legal_p__hzARZ"><span>The receiving party shall hold Confidential Information in confidence and not make Confidential Information available to any third party or use Confidential Information for any purpose other than for the purposes set out in these Terms. The receiving party shall take all reasonable steps to ensure that Confidential Information to which it has access is not disclosed, distributed, or used by its employees or agents in violation of the terms of this Agreement.</span></p>
                    <p className="legal_p__hzARZ"><span>This section shall survive termination of these Terms and continue in force for a period of three (3) years after the termination of these Terms.</span></p>
                    <h2 className="legal_h2___qfIT">Third-party services and terms</h2>
                    <p className="legal_p__hzARZ"><span>The Services may contain links to websites operated by third parties or feature content that is made available by third-party service providers and enable you to access and use certain third-party services. Use of any websites operated by third parties, third-party services, and/or content in third-party services shall be, in addition to these Terms, subject to the relevant third-party service provider’s terms and conditions applicable to use of such third-party services or the applicable license(s) under which any such content is made available.</span></p>
                    <p className="legal_p__hzARZ"><span>JobGo assumes no responsibility for such third-party services and/or content. If you access a third-party site or services from the Services on or through any third-party site or service, you do so at your own risk, and you understand that these Terms and JobGo’s Privacy Policy do not apply to your use of such sites. In addition, a link to a third-party service or website does not imply that JobGo endorses the site or the information, products, or services referenced in such third-party service or website.</span></p>
                    <h2 className="legal_h2___qfIT">Security and personal data</h2>
                    <p className="legal_p__hzARZ"><span>JobGo takes appropriate measures to prevent unauthorized access to, improper use of, and the reasonable accuracy of any processed personal data. JobGo uses various technologies and protocols to ensure high security standards. Any data that is stored on JobGo servers is not generally available to the public. However, the use of internet services always includes security risks. You are always liable for all your own equipment, internet connectivity, and software as well as that they do not cause harm to JobGo and/or third parties.</span></p>
                    <p className="legal_p__hzARZ"><span>Use of your personal data is governed by JobGo’s Privacy Policy at https://jobgo.ai/privacy-policy.</span></p>
                    <p className="legal_p__hzARZ"><span>In addition, the Data Protection Appendix attached to these Terms shall apply when JobGo acts as a processor with respect to personal data included in the content you submit to the Services and processes such personal data on your behalf.</span></p>
                    <h2 className="legal_h2___qfIT">Support and changes</h2>
                    <p className="legal_p__hzARZ"><span>We use reasonable efforts to ensure that the Services are available 24 hours a day, 7 days a week, but we cannot promise that access to the Services will be uninterrupted or error-free. There may be occasions when access to the Service is interrupted due to, e.g., regular maintenance.</span></p>
                    <p className="legal_p__hzARZ"><span>Free support assisting in the use of Services is provided, at our sole discretion and without any warranties or service level guarantees for response times, by chat service incorporated in the Services.</span></p>
                    <p className="legal_p__hzARZ"><span>JobGo may change the Services by adding, modifying, or removing any features or functionalities. We may also stop providing parts of, or the whole of, the Services. We may also create new operating guidelines, limitations, or terms applicable to the Services at any time.</span></p>
                    <h2 className="legal_h2___qfIT">Limitation of liability</h2>
                    <p className="legal_p__hzARZ"><span>To the maximum extent permitted by applicable law and except as explicitly provided in these Terms, JobGo makes no warranty or representation, either expressed or implied with respect to the Services, their correctness, quality, performance, non-infringement, or fitness for a particular purpose. As a result, the Services are provided “as is” and “as available,” and you are assuming the entire risk as to their quality and performance. JobGo does not warrant that the Service will be uninterrupted or error-free.</span></p>
                    <p className="legal_p__hzARZ"><span>JobGo shall not be liable for any direct, indirect, incidental, special, or consequential damages, lost profits, data, or for business interruption arising out of the use of or inability to use the Services, even if JobGo has been advised of the possibility of such damages. In no event shall JobGo be liable to you for any damages, losses, or costs in an amount exceeding the amount you paid to JobGo under these Terms or EUR 100.00, whichever is greater.</span></p>
                    <p className="legal_p__hzARZ"><span>You understand and acknowledge that JobGo will not be liable for any network-related problems attributable to the operation of the Services and that network configuration changes may affect the system’s performance. You are liable for all your own equipment, internet connectivity, and software, as well as that they do not cause harm to JobGo and/or third parties.</span></p>
                    <p className="legal_p__hzARZ"><span>The limitations of this section apply only to the maximum extent permitted by applicable law.</span></p>
                    <h2 className="legal_h2___qfIT">Indemnification</h2>
                    <p className="legal_p__hzARZ"><span>You agree to indemnify and hold harmless JobGo from and against any and all third party claims and all liabilities, assessments, losses, costs, or damages, including reasonable attorneys{/* */}'{/* */} fees, resulting from or arising out of a) any content you submit, post, transmit, or otherwise make available through the Services; b) your use of the Services; c) any violation by you of these Terms; or d) your actual or alleged violation of any third party rights. This obligation shall survive the termination or expiration of these Terms and/or your use of the Services. JobGo reserves the right to assume its own defense and/or control of any matter otherwise subject to indemnification by you hereunder.</span></p>
                    <h2 className="legal_h2___qfIT">Force Majeure</h2>
                    <p className="legal_p__hzARZ"><span>JobGo shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of JobGo, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond JobGo’s control such as war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, epidemics, pandemics strikes, or shortages of transportation facilities, fuel, energy, labor or materials.</span></p>
                    <h2 className="legal_h2___qfIT">Termination</h2>
                    <p className="legal_p__hzARZ"><span>The license to use the Services granted to you under these Terms remains in effect until the access to the Services is terminated by you or JobGo. You may stop using our Service at any time. JobGo may terminate or suspend access to our Services immediately if you breach these Terms or fail to make any payments hereunder. Your right to use the Premium Version terminates upon the end of the applicable subscription period.</span></p>
                    <p className="legal_p__hzARZ"><span>Upon termination in accordance with these Terms for any reason, you must cease all use of the Services and promptly delete any applications associated with the Services. Your termination of the license for any reason shall not release you from any liabilities or obligations set forth in these Terms which a) have expressly been stated as surviving any such termination or expiration, or b) remain to be performed, or by their nature would be intended to be applicable following any such termination or expiration.</span></p>
                    <p className="legal_p__hzARZ"><span>You can extract the content stored by you in the Services at any time when your use rights to the Services are in effect. In case you wish to extract such content after your right to use the Services has been terminated, we may agree to assist you in the same at an additional cost.</span></p>
                    <p className="legal_p__hzARZ"><span>You acknowledge and accept that the cancellation of the license is your sole right and remedy with respect to any dispute related to using the Services.</span></p>
                    <h2 className="legal_h2___qfIT">Miscellaneous</h2>
                    <p className="legal_p__hzARZ"><span>JobGo may assign or delegate its rights and obligations under the Terms, in whole or in part, to any person or entity at any time without your consent. You may not assign or delegate rights or obligations under the Terms without JobGo’s prior written consent.</span></p>
                    <p className="legal_p__hzARZ"><span>These Terms contain the entire understanding between you and JobGo and supersede all prior agreements between you and JobGo.</span></p>
                    <p className="legal_p__hzARZ"><span>These Terms describe certain legal rights. You may have other rights under the mandatory provisions of the laws of your country. These Terms do not change your rights under the mandatory provisions under the laws of your country. You and JobGo agree that if any portion of the Terms or if JobGo Privacy Policy is found illegal or unenforceable, in whole or in part by any court of competent jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the extent of such determination of invalidity or unenforceability without affecting the validity or enforceability thereof in any other manner or jurisdiction and without the remaining provisions of the terms, which shall continue to be in full force and effect.</span></p>
                    <p className="legal_p__hzARZ"><span>JobGo reserves the right to change, modify, add or remove portions of the Terms and its Privacy Policy at any given time. You will be deemed to have accepted such changes by continuing to use the Services. JobGo will, however, notify users by posting a note in the Services of any substantial changes to the Terms. Further, if at any point you do not agree to any portions of the then-current version of our Terms, JobGo’s Privacy Policy or any other JobGo policy, rules or codes of conduct relating to your use of the Services, your license to use the Services shall immediately terminate, and you must immediately stop using the Services. You can always cease using the Services by not using the solution. Please check the Services on a regular basis so that you remain informed of the then-current Terms.</span></p>
                    <h2 className="legal_h2___qfIT">Applicable laws and disputes</h2>
                    <p className="legal_p__hzARZ"><span>These Terms shall be governed by and shall be construed in accordance with the laws of Finland, excluding its choice of law provisions.</span></p>
                    <p className="legal_p__hzARZ"><span>Any dispute or claim for compensation arising from the Services or these Terms will be resolved primarily through negotiations between JobGo and you.</span></p>
                    <p className="legal_p__hzARZ"><span>Any dispute or controversy or claim arising out of or relating to the Services or these Terms shall be finally resolved by arbitration in accordance with the Arbitration Rules of the Finland Chamber of Commerce by one (1)arbitrator appointed according to the aforementioned rules. The arbitration shall be conducted in the English (or Finnish if both parties so agree) language in Helsinki, Finland.</span></p>
                    <h2 className="legal_h2___qfIT">Contact us</h2>
                    <p className="legal_p__hzARZ"><span>If you have any questions about these Terms, please contact us: support@JobGo.com</span></p>
                    <p className="legal_p__hzARZ"><span>JobGo International Oy</span></p>
                    <p className="legal_p__hzARZ"><span>‍Business ID: 2565517-7</span></p>
                    <p className="legal_p__hzARZ"><span>Address: Keilaranta 3, 02150 Espoo, Finland</span></p>
                    <p className="legal_p__hzARZ"><span>Email: </span><a href="mailto:support@JobGo.com" target="_blank" rel="noreferrer"><span>support@JobGo.com</span></a></p>
                    <h1 className="legal_h1__elvz0">Data Protection Appendix</h1>
                    <p className="legal_p__hzARZ"><span>This data protection appendix (“</span><span><strong>DPA</strong></span><span>”) is applied to the processing of personal data under the Terms between JobGo Oy (“</span><span><strong>Processor</strong></span><span>”) as a data processor and you (“</span><span><strong>Controller</strong></span><span>”) as a controller.</span></p>
                    <p className="legal_p__hzARZ"><span>The Processor and the Controller are referred to individually as “</span><span><strong>Party</strong></span><span>” and collectively as “</span><span><strong>Parties</strong></span><span>”.</span></p>
                    <h2 className="legal_h2___qfIT">1 Background and purpose</h2>
                    <p className="legal_p__hzARZ"><span>The scope of the Services for which the personal data is processed is described in the Terms. This DPA is a part of the Terms and supersedes any conflicting provisions of the Terms concerning data processing.&nbsp;</span></p>
                    <p className="legal_p__hzARZ"><span>The Processor shall act in accordance with this DPA while providing the Services and follow the Controller’s instructions concerning data protection and the Data Protection Laws while offering the Services to the Controller. “</span><span><strong>Data Protection Laws</strong></span><span>” means national and EU legislation concerning data protection, such as General Data Protection Regulation (“</span><span><strong>GDPR</strong></span><span>”, EU 2016/679). The terms related to data protection which are not defined in this DPA, are used in accordance with the GDPR.</span></p>
                    <h2 className="legal_h2___qfIT">2 Processing of personal data</h2>
                    <p className="legal_p__hzARZ"><span>The scope of the processing is described further in the </span><span>Annex A</span><span> {/* */}(Description on the Processing of Personal Data). Any expanding of the processing of personal data must be agreed separately.&nbsp;</span></p>
                    <p className="legal_p__hzARZ"><span>You expressly acknowledge and agree that sensitive personal data, such as health data or data revealing religious beliefs, should not be transferred to the Services.</span></p>
                    <p className="legal_p__hzARZ"><span>Both Parties shall be responsible to ensure that processing of personal data is done in accordance with Data Protection Laws and good data processing practices.</span></p>
                    <p className="legal_p__hzARZ"><span>The Controller shall take the necessary measures to ensure that the processing of personal data to be transferred to the Processor complies with the Data Protection Laws.</span></p>
                    <p className="legal_p__hzARZ"><span>The Processor shall process personal data in compliance with the written instructions given by the Controller as well as the Data Protection Laws and this DPA. The Processor shall notify the Controller without delay if the Processor considers that the Controller’s instructions infringe the Data Protection Laws. At the effective date of the Terms, the complete written instructions given by the Controller to the Processor are included in this DPA and the Terms. The implementation of the Controller’s additional or amended instructions shall be agreed separately.</span></p>
                    <h2 className="legal_h2___qfIT">3 Confidentiality</h2>
                    <p className="legal_p__hzARZ"><span>The Processor and people working under the Processor must keep the personal data received from the Controller confidential and ensure that only authorised persons can access the personal data for processing. The Processor shall ensure that persons authorised to process the personal data have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality.</span></p>
                    <h2 className="legal_h2___qfIT">4 Duration of the obligations</h2>
                    <p className="legal_p__hzARZ"><span>This DPA is in force for as long as the Terms are in force and for as long as, after the termination of the Terms, it is necessary to complete the tasks related to the processing of personal data (such as returning the personal data to the Controller and/or deleting the personal data in accordance with the Controller’s instructions) or longer, if required by applicable legislation.</span></p>
                    <p className="legal_p__hzARZ"><span>Upon termination of the Terms, the Processor shall delete (or, if separately agreed and at Controller’s expense, return) within a reasonable time all personal data processed on behalf of the Controller, unless the retention of said personal data is required under the Data Protection Laws.&nbsp;</span></p>
                    <h2 className="legal_h2___qfIT">5 Subprocessors</h2>
                    <p className="legal_p__hzARZ"><span>The Processor may use subprocessors in the processing of personal data based on this DPA. The current list of approved subprocessors is included below:</span></p>
                    <div style={{marginBottom:50}}>
                        <table>
                            <tbody>
                                <tr>
                                    <td><span><strong>Name of the company</strong></span></td>
                                    <td><span><strong>Address</strong></span></td>
                                    <td><span><strong>Contact</strong></span></td>
                                </tr>
                                <tr>
                                    <td><span>Mixpanel, Inc.</span></td>
                                    <td><span>One Front Street, 28th Floor, San Francisco, CA 94111</span></td>
                                    <td><span>compliance@mixpanel.com</span></td>
                                </tr>
                                <tr>
                                    <td><span>OpenAI, Inc.</span></td>
                                    <td><span>3180 18th St., San Francisco, CA 94110</span></td>
                                    <td><span>compliance@openai.com</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="legal_p__hzARZ"><span>The Processor shall ensure that the subprocessors are bound by a written contract that complies with the obligations set forth in Article 28 of the GDPR. The Processor is responsible for its subprocessors’ obligations under this DPA the same way as it is responsible for its own obligations.</span></p>
                    <p className="legal_p__hzARZ"><span>The Processor shall inform the Controller in writing of any intended changes concerning the addition of new subprocessors processing personal data before the change takes effect. If the Controller objects the change, it may terminate the Terms by a thirty (30) days’ written notice to the Processor. If the Controller does not object the change within seven (7) days from the receipt of the written notice, the Processor may use the new subprocessors.&nbsp;</span></p>
                    <h2 className="legal_h2___qfIT">6 Processing personal data outside the EU/EEA</h2>
                    <p className="legal_p__hzARZ"><span>The Processor shall not transfer personal data to a country outside the EU/EEA or otherwise process personal data in a country outside the EU/EEA without prior written authorization of the Controller.</span></p>
                    <p className="legal_p__hzARZ"><span>If personal data is transferred outside the EU/EEA, there must be appropriate safeguards in place for the transfer such as standard data protection clauses adopted by the Commission. The Processor shall provide evidence of these safeguards to the Controller upon request.</span></p>
                    <h2 className="legal_h2___qfIT">7 Information security</h2>
                    <p className="legal_p__hzARZ"><span>The Processor shall take any appropriate technical and organisational measures to combat and prevent unauthorised and unlawful processing of personal data and to prevent unintentional loss, change, destruction of or damage to personal data.</span></p>
                    <p className="legal_p__hzARZ"><span>The Processor shall notify the Controller without undue delay in writing of any personal data breach or other incidents where the security of the personal data processed on behalf of the Controller is compromised or may be compromised.</span></p>
                    <p className="legal_p__hzARZ"><span>The data breach notification shall at least:</span></p>
                    <ol>
                        <li><span>describe the nature of the personal data breach including where possible, the categories and approximate number of data subjects concerned and the categories and approximate number of personal data records concerned;</span></li>
                        <li><span>communicate the name and contact details of a person from whom more information can be obtained;</span></li>
                        <li><span>describe the likely consequences of the personal data breach;</span></li>
                        <li><span>describe the measures taken or proposed to be taken by the Processor to address the personal data breach, including, where appropriate, measures to mitigate its possible adverse effects.</span></li>
                    </ol>
                    <p className="legal_p__hzARZ"><span>Where it is not possible to provide the above-mentioned information at the same time, the information may be provided in phases without undue further delay.</span></p>
                    <h2 className="legal_h2___qfIT">8 Right to audit</h2>
                    <p className="legal_p__hzARZ"><span>The Controller and/or a third-party mandated by the Controller, shall have the right to conduct an audit, including inspections, to evaluate the fulfilment of the Processor’s obligations under this DPA for the data protection and the level of security for processing personal data. The mandated third party may not be a competitor of the Processor.</span></p>
                    <p className="legal_p__hzARZ"><span>The scope and the time of the audit shall always be separately agreed upon between the Parties and the audit may not unnecessarily burden the Processor or endanger the Processor’s or the Processor’s other customers’ delivery, quality, security or confidentiality.</span></p>
                    <p className="legal_p__hzARZ"><span>Each Party is liable for its own costs related to the audit provided that the Controller shall bear the costs of the third party auditor. In case more than one (1) audit takes place within a calendar year, the Controller shall bear all costs incurred by the Processor.</span></p>
                    <h2 className="legal_h2___qfIT">9 Obligation to assist</h2>
                    <p className="legal_p__hzARZ"><span>Considering the nature of the processing and the information available to the Processor, the Processor shall without undue delay upon the Controller’s written request and at the Controller’s expense provide the Controller with assistance in fulfilling the Controller’s (i) obligation to answer the data subjects’ requests concerning their rights as required in the Data Protection Laws, where the Controller is not able to fulfil such requests alone, and (ii) other obligations of controller arising from the Data Protection Laws, where the Controller requires the Processor’s assistance.</span></p>
                    <p className="legal_p__hzARZ"><span>The Processor shall, without delay, inform the Controller of all requirements and inquiries made by the data subjects or the data protection authorities.</span></p>
                    <h2 className="legal_h2___qfIT" style={{marginBottom : 50}}>Annex A: Description on the Processing of Personal Data</h2>
                    <p className="legal_p__hzARZ"><span>The Processor provides Services to the Controller that will include processing of personal data by the Processor as further specified below with respect to: (a) the nature and purpose of the processing of personal data; (b) the type of personal data and categories of data subjects; (c) the applicable information security measures; and (d) duration of the processing of Personal Data under the Agreement, as further agreed below.</span></p>
                    <h3>a) The nature and purpose of the processing of personal data&nbsp;</h3>
                    <p className="legal_p__hzARZ"><span>The Processor processes personal data on behalf of the Controller in order to fulfil his obligations under the Terms in relation to provision of the Services insofar as the service requires processing of personal data.</span></p>
                    <h3>b) The type of personal data and categories of data subjects&nbsp;</h3>
                    <p className="legal_p__hzARZ"><span>Personal data included in the content you provide to the Services, such as:</span></p>
                    <ul>
                        <li><span>Names of the candidates</span></li>
                        <li><span>Contact details of the candidates, such as addresses, email addresses, phone numbers</span></li>
                        <li><span>Personal data included in the job interview question and responses</span></li>
                        <li><span>Personal data included in your notes stored in the Services</span></li>
                    </ul>
                    <p className="legal_p__hzARZ"><span>The Service is not intended for processing of sensitive personal data, such as health related data or data revealing religious beliefs.</span></p>
                    <h3>c) The applicable information security measures</h3>
                    <p className="legal_p__hzARZ"><span>The Controller’s information security instructions are set out in Section 7 (Information Security) of this DPA.</span></p>
                    <h3>d) Duration of the processing of personal data under the Agreement</h3>
                    <p className="legal_p__hzARZ"><span>The Processor processes personal data on behalf of the Controller during the validity of the Terms and after termination for the actions required according to the Terms and this DPA.</span></p>
                </section>
            </main>
        </>
    )
}

export default TermsOfService;