import React from "react";
import Router from "./Route/Router";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

const App = () => {
  return (
    <>
      <div id="__next" data-reactroot>
        <div className="container">
          <Header />
          <Router />
          <Footer />
        </div>

        {/* <aside className="cookies_container__PZqz_">
          <p className="cookies_content__k_S3T">
            We use third-party
            <a className="cookies_link__FKSzX" href="cookies-policy.html">cookies</a>
            to personalise your site experience. By clicking “Accept” you consent to our use of cookies.
          </p>
          <div className="cookies_buttonContainer__g4ZVo"><button className="cookies_button__i2YtF">Decline</button><button className="cookies_button__i2YtF">Accept</button></div>
        </aside> */}
      </div>
    </>
  );
}

export default App;
