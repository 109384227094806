import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="darkmode">
                <footer className="footer_footer__WCChH max-width">
                    <div className="footer_logo__Yz8o2">
                        <Link className="footer_logoImage__YMNU7" to="/" style={{width: 90}}>
                            <span className="images">
                                <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                    <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                        <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="_next/static/media/logo.ae1406cb.svg" />
                                    </span>
                                    {/* <img alt="hireproof logo" srcSet="_next/static/media/logo.ae1406cb.svg 1x, _next/static/media/logo.ae1406cb.svg 2x" src="_next/static/media/logo.ae1406cb.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} /> */}
                                </span>
                            </span>
                        </Link>
                    </div>
                    <div className="footer_footerContainer__9aEfd">
                        <div className="footer_addressContainer__pY2f2">
                            <h3 className="footer_linksHeader__LNAwy">Our Office</h3>
                            <div className="footer_address__uaEcv">
                                <div>JobGo.AI</div>
                                <div>Keilaranta 3</div>
                                <div>02150 Espoo,</div>
                                <div>Finland</div>
                            </div>
                            <a href="mailto:support@jobgo.com" style={{color: 'white'}}>support@jobgo.com</a>
                        </div>
                        <div className="footer_socialContainer__QVq1b">
                            <div>
                                <h3 className="footer_linksHeader__LNAwy">Discover</h3>
                                <ul className="footer_linksContainer__1WVPJ">
                                <li className="footer_linksLi___VH8E"><Link className="footer_link__8UgOK" to="/">Home</Link></li>
                                    <li className="footer_linksLi___VH8E"><Link className="footer_link__8UgOK" to="/price">Pricing</Link></li>
                                    <li className="footer_linksLi___VH8E"><Link className="footer_link__8UgOK" to="/company">Company</Link></li>
                                    {/* <li className="footer_linksLi___VH8E"><Link className="footer_link__8UgOK" to="/interviewGuides">Interview guides</Link></li> */}
                                </ul>
                            </div>
                            <div>
                                <h3 className="footer_linksHeader__LNAwy">Legal</h3>
                                <ul className="footer_linksContainer__1WVPJ">
                                    <li className="footer_linksLi___VH8E"><a className="footer_link__8UgOK" href="terms-of-service.html">Terms of Service</a></li>
                                    <li className="footer_linksLi___VH8E"><a className="footer_link__8UgOK" href="privacy-policy.html">Privacy Policy</a></li>
                                    <li className="footer_linksLi___VH8E"><a className="footer_link__8UgOK" href="cookies-policy.html">Coookie Policy</a></li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="footer_linksHeader__LNAwy">Follow us</h3>
                                <ul className="footer_linksContainer__1WVPJ">
                                    <li className="footer_linksLi___VH8E"><a className="footer_link__8UgOK" href="#">Instagram</a></li>
                                    <li className="footer_linksLi___VH8E"><a className="footer_link__8UgOK" href="#">Facebook</a></li>
                                    <li className="footer_linksLi___VH8E"><a className="footer_link__8UgOK" href="#">Linkedin</a></li>
                                    <li className="footer_linksLi___VH8E"><a className="footer_link__8UgOK" href="#">Twitter</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer_footerFooter__eIuY5">
                        <div className="footer_footerFooterContent__herpO">© Copyright 2024 Jobgo.</div>
                        <div className="footer_footerFooterContent__herpO">
                            <svg className="footer_svg__W3Les" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.8239 16.8934C8.2245 15.923 6.73661 14.781 5.38685 13.4876C4.4379 12.5562 3.71548 11.4207 3.27491 10.1682C2.48211 7.7383 3.40816 4.95658 5.99977 4.13335C7.36182 3.70109 8.85605 4.65052 10.0038 5.49962C11.152 4.65155 12.6323 3.70221 13.9945 4.13335C16.5861 4.95658 17.5188 7.7383 16.726 10.1682C16.2854 11.4207 15.563 12.5562 14.6141 13.4876C13.2643 14.781 11.7764 15.923 10.177 16.8934L10.0038 16.9996L9.8239 16.8934Z" stroke="currentColor" strokeWidth="1.3" />
                            </svg>
                            Made in Europe
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Footer;