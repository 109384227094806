import React from "react";

const PrivacyPolicy = () => {
    return (
        <>
            <main className="main privacy-policy">
                <section className="legal_container__PCd_C">
                    <h1 className="legal_h1__elvz0">Privacy Policy</h1>
                    <p className="legal_p__hzARZ">
                        <span>This policy describes how JobGo processes your data.</span>
                    </p>
                    <p className="legal_p__hzARZ">
                        <span>This privacy policy describes how JobGo International Oy (”</span>
                        <span><strong>JobGo</strong></span>
                        <span>,” “</span>
                        <span><strong>we</strong></span>
                        <span>” or “</span>
                        <span><strong>us</strong></span>
                        <span>”)  processes personal data; e.g., what kinds of personal data we collect, for which purposes the personal data is used, and to which parties the personal data can be disclosed.</span>
                    </p>
                    <p className="legal_p__hzARZ">
                        <span>This privacy policy applies to users of the JobGo platform, all of its separate applications, and services related to offering the solution.</span>
                    </p>
                    <p className="legal_p__hzARZ">
                        <span>Personal data refers to any information relating to a natural person (“</span>
                        <span><strong>data subject</strong></span>
                        <span>”) that can identify them directly or indirectly. Personal data, data subject, controller, and other key terms are defined in the General Data Protection Regulation (2016/679, “</span>
                        <span><strong>GDPR</strong></span>
                        <span>”). JobGo  complies with the GDPR in all processing of personal data in conjunction with other applicable national data protection legislation (“</span>
                        <span><strong>data protection legislation</strong></span>
                        <span>”).</span>
                    </p>
                    <p className="legal_p__hzARZ">
                        <span>Our services may also contain links to external websites and services operated by other organizations that we do not manage. This privacy policy is not applicable to their use, so we encourage you to review the privacy policies that apply to them. We are not responsible for the privacy policies of other websites or external services.</span>
                    </p>

                    <h2 className="legal_h2___qfIT">Contact information</h2>
                    <p className="legal_p__hzARZ">
                        <span>Controller: JobGo International Oy</span>
                    </p>
                    <p className="legal_p__hzARZ">
                        <span>Business ID: 2565517-7</span>
                    </p>
                    <p className="legal_p__hzARZ">
                        <span>Address: Keilaranta 3, 02150 Espoo, Finland</span>
                    </p>
                    <p className="legal_p__hzARZ">
                        <span>Email: support@JobGo.com</span>
                    </p>

                    <h2 className="legal_h2___qfIT">Why do we process personal data?</h2>
                    <p className="legal_p__hzARZ">
                        <span>We may process personal data for the following purposes:</span>
                    </p>

                    <ul>
                        <li>
                            <span>Delivery of our products and services for you based on contract or its preparation, for example, when you use our services.</span>
                        </li>
                        <li>
                            <span>Managing and analyzing the customer relationship and use of the JobGo solution based on JobGo’s legitimate interest.</span>
                        </li>
                        <li>
                            <span>Communication with you, for example, to send you alerts and notifications relating to our products and services, inform you that our product and services have changed, and ask for your feedback on our products and services based on contract or its preparation or JobGo ’s legitimate interest.</span>
                        </li>
                        <li>
                            <span>Complying and fulfilling our legal duties and obligations such as tax law and accounting-related obligations based on statutory obligations.</span>
                        </li>
                        <li>
                            <span>Ensuring the security of our products, services, and IT environments and preventing abuses based on statutory obligation or JobGo ’s legitimate interest.</span>
                        </li>
                        <li>
                            <span>Provision of information and materials related to our products and services, for example by newsletters and direct marketing based on JobGo ’s legitimate interest or consent.</span>
                        </li>
                        <li>
                            <span>Direct marketing based on JobGo ’s legitimate interest in sending you promotional material about products and services you might be interested in. The data subject has the right to refuse personal data being used for direct marketing and may at any time recall prior consent.</span>
                        </li>
                    </ul>

                    <p className="legal_p__hzARZ">
                        <span>For processing activities that are based on a legitimate interest, we have carefully balanced such legitimate interest with the data subjects' right to privacy and concluded that our interest outweighs the data subjects’ rights and freedoms.</span>
                    </p>

                    <p className="legal_p__hzARZ">
                        <span>Where the processing is such that consent is required by the applicable legislation, we will state so and obtain the consent, and this will be the legal basis for the processing. However, you have the right to withdraw that consent at any time without affecting the lawfulness of processing based on consent before its withdrawal. If such withdrawal means that we are no longer able to provide our services, we may cease to provide the services.</span>
                    </p>

                    <h2 className="legal_h2___qfIT">What data is collected, stored, and processed?</h2>
                    <p className="legal_p__hzARZ">
                        <span>JobGo  collects only such personal data from the data subject that is relevant and necessary for the purposes described in this privacy policy.</span>
                    </p>

                    <p className="legal_p__hzARZ">
                        <span>The following personal data from the data subjects will be processed:</span>
                    </p>

                    <ul>
                        <li><span>First and last name</span></li>
                        <li><span>Contact information (postal address, e-mail address, phone numbers)</span></li>
                        <li><span>Job title and employer (past and current)</span></li>
                        <li><span>Username and password</span></li>
                        <li><span>Information on the use of the JobGo  solution as well as content created by you</span></li>
                        <li><span>Technical data sent to JobGo  by your digital devices (such as computers and mobile devices) as well as information related to cookies and other similar technologies</span></li>
                        <li><span>Recordings, transcripts and/or notes of interactions (meetings, phone calls, etc.) with JobGo  personnel as well as e-mail and chat correspondence</span></li>
                        <li><span>Direct marketing preferences</span></li>
                        <li><span>Campaigns and promotions directed to you, as well as their use</span></li>
                        <li><span>Information of data subjects who have made purchases, given feedback and/or made complaints related to JobGo  solution</span></li>
                        <li><span>Purchase history (incl. contracts) billing, and payment collection data</span></li>
                    </ul>


                    <h2 className="legal_h2___qfIT">Where do we gather your personal data from?</h2>
                    <p className="legal_p__hzARZ"><span>The personal data is mainly collected directly from the data subjects themselves, for example, at the time of registration or use of the JobGo solution or when contacting us.</span></p>
                    <p className="legal_p__hzARZ"><span>The personal data may also be collected automatically when the data subject uses our products and services e.g., when using our online services.</span></p>
                    <p className="legal_p__hzARZ"><span>In addition, and with the permission of the data subject, data may be collected in other ways in a marketing context.</span></p>
                    <p className="legal_p__hzARZ"><span>Personal data may be updated and supplemented by collecting data from private and public sources.</span></p>
                    <h2 className="legal_h2___qfIT">How long do we process your personal data?</h2>
                    <p className="legal_p__hzARZ"><span>Personal data collected in connection with our services shall be retained as long as needed for the purposes defined in this privacy policy and as required by the law, unless such data is replaced through regular updates or otherwise. The periods vary greatly from one type of processing to another.</span></p>
                    <p className="legal_p__hzARZ"><span>We retain personal data only for a period that is necessary to achieve the purposes for which personal data is processed unless there is a legal obligation to retain personal data for a longer period of time(for example, responsibilities and obligations under specific legislation, accounting or reporting obligations). JobGo may retain information for a longer period of time if it is required, for example, to exercise a legal claim, to defend a legal claim, or to settle a similar dispute. In general, we observe the following criteria for retaining and deleting personal data:</span></p>
                    <ul>
                        <li><span>JobGo solution users’ personal data is retained for the duration of using the JobGo’s services and thereafter for a maximum period of 2 years following the last login.</span></li>
                        <li><span>Electronic marketing subscribers’ personal data is retained until the subscription is withdrawn.</span></li>
                    </ul>
                    <p className="legal_p__hzARZ"><span>Detailed retention times can be provided upon requests.</span></p>
                    <p className="legal_p__hzARZ"><span>We evaluate the necessity and accuracy of the personal data on a regular basis and endeavor to ensure that the incorrect and unnecessary personal data are corrected or deleted.</span></p>
                    <h2 className="legal_h2___qfIT">Is your data shared with others?</h2>
                    <p className="legal_p__hzARZ"><span>Personal data will mainly be accessed by our employees for the purposes specified in this privacy policy. For the purposes stated in this privacy policy, the personal data may be disclosed, when necessary, to authorities, other companies within the same group of companies as us, and to other third parties, such as third-party service providers and our subcontractors (such as software and hosting services, customer service providers and marketing agencies conducting marketing on our behalf, etc.). In such case, the personal data will only be disclosed for purposes defined above and any disclosure is always limited to only the strictly necessary personal data included in such purposes. We do not sell or otherwise disclose personal data to third parties outside JobGo for such third parties’ own purposes.</span></p>
                    <p className="legal_p__hzARZ"><span>In addition, we may share the personal data in connection with any merger, sale of our assets, or financing or acquisition of all or a portion of our business and in connection with other similar arrangements.</span></p>
                    <p className="legal_p__hzARZ"><span>The personal data is also disclosed to third parties if required under any applicable law or regulation or order by competent authorities, and to investigate possible infringing use of the products and services as well as to guarantee the safety of the JobGo products and services. In the event of emergencies or other unexpected circumstances, JobGo may be required to disclose the personal data of registered persons in order to protect human life, health, and property.</span></p>
                    <p className="legal_p__hzARZ"><span>List of the processors and other recipients:</span></p>
                    <ul>
                        <li><span>Intercom, Inc., provider of the Intercom chat service incorporated in the JobGo solution</span></li>
                        <li><span>Mixpanel, for application analytics</span></li>
                        <li><span>Sentry, for tracking errors that users face in the application</span></li>
                    </ul>
                    <h2 className="legal_h2___qfIT">Is data transferred outside the European Union or the European Economic area?</h2>
                    <p className="legal_p__hzARZ"><span>JobGo does not, as a rule, transfer personal data outside the European Union (EU) or the European Economic Area (EEA).</span></p>
                    <p className="legal_p__hzARZ"><span>In case personal data is transferred outside the European Union (EU) or the European Economic Area (EEA), such transfers are either made to a country that is deemed to provide a sufficient level of privacy protection by the European Commission or transfers are carried out by using appropriate safeguards such as standard data protection clauses (SCC) adopted, including any supplementary measures, where assessed to be necessary, or otherwise approved by the EU Commission or competent data protection authority in accordance with the GDPR.</span></p>
                    <h2 className="legal_h2___qfIT">How is the data protected?</h2>
                    <p className="legal_p__hzARZ"><span>Securing the integrity and confidentiality of personal data is important to JobGo. We have taken adequate technical and organizational measures in accordance with industry standards in order to keep personal data safe and to secure it against unauthorized access, loss, misuse, or alteration by third parties, such as by firewalls, physical security measures, access controls, assignment of access rights, encryption and active monitoring of the aforementioned measures.</span></p>
                    <p className="legal_p__hzARZ"><span>Nevertheless, considering the cyber threats in the modern-day online environment, we cannot give a full guarantee that our security measures will prevent illegally and maliciously operating third parties from obtaining access to personal data or absolute security of the personal data during its transmission or storage on our systems.</span></p>
                    <p className="legal_p__hzARZ"><span>All parties processing personal data have a duty of confidentiality in matters related to the processing of personal data. Access to personal data is restricted to those employees and parties who need it to perform their duties. We also require our service providers to have appropriate methods in place to protect personal data.</span></p>
                    <h2 className="legal_h2___qfIT">Automated decision-making and profiling</h2>
                    <p className="legal_p__hzARZ"><span>JobGo does not use any automated decision-making or any profiling pursuant to Article 22GDPR.</span></p>
                    <h2 className="legal_h2___qfIT">Rights of data subjects</h2>
                    <p className="legal_p__hzARZ"><span>The data subject has a number of rights under applicable data protection laws.</span></p>
                    <h2 className="legal_h2___qfIT">Right of access and right of inspection</h2>
                    <p className="legal_p__hzARZ"><span>The data subject has the right to obtain confirmation as to whether or not personal data concerning them is being processed.</span></p>
                    <p className="legal_p__hzARZ"><span>The data subject has the right to inspect and view data concerning them and, upon a request, the right to obtain the data in a written or electric form. This applies to information that the data subject has provided to JobGo insofar as the processing is based on a contract/consent.</span></p>
                    <h2 className="legal_h2___qfIT">Right to rectification and right to erasure</h2>
                    <p className="legal_p__hzARZ"><span>The data subject has the right to demand the rectification of inaccurate personal data concerning them and to have incomplete personal data completed.</span></p>
                    <p className="legal_p__hzARZ"><span>The data subject has the right to require JobGo to delete or stop processing the data subject’s personal data, for example, where the data is no longer necessary for the purposes of the processing. However, please note that certain personal data is strictly necessary in order to achieve the purposes defined in this privacy policy and may also be required to be retained by applicable laws.</span></p>
                    <h2 className="legal_h2___qfIT">Right to data portability</h2>
                    <p className="legal_p__hzARZ"><span>The data subject has the right to receive the personal data that they have provided to JobGo in a structured, commonly used, and machine-readable format and, if desired, transmit that data to another controller. The right to data portability applies to the processing of personal data based on consent or a contract.</span></p>
                    <h2 className="legal_h2___qfIT">Right to restriction of processing</h2>
                    <p className="legal_p__hzARZ"><span>The data subject has the right, under conditions defined by data protection legislation, to request the restriction of processing of their personal data. In situations where personal data suspected to be incorrect cannot be corrected or removed, or if the removal request is unclear, we will limit access to such data.</span></p>
                    <h2 className="legal_h2___qfIT">Right to object to processing</h2>
                    <p className="legal_p__hzARZ"><span>The data subject has the right to object to the processing of data where JobGo is relying on its legitimate interests as the legal ground for the processing. For example, the data subject may object to their personal data being used for marketing purposes.</span></p>
                    <h2 className="legal_h2___qfIT">Right to withdraw consent</h2>
                    <p className="legal_p__hzARZ"><span>In cases where the processing is based on the data subjects’ consent, the data subject has the right to withdraw their consent to such processing at any time.</span></p>
                    <h2 className="legal_h2___qfIT">Right to lodge a complaint with a supervisory authority</h2>
                    <p className="legal_p__hzARZ"><span>The data subject has the right to lodge a complaint with a competent data protection authority if the data subject considers that the processing of personal data relating to the data subject infringes current legislation.</span></p>
                    <p className="legal_p__hzARZ"><span>However, we request that the matter will be dealt with JobGo in the first instance.</span></p>
                    <p className="legal_p__hzARZ"><span>The relevant authority in Finland is the Data Protection Ombudsman (</span><a href="http://www.tietosuoja.fi" target="_blank" rel="noreferrer"><span>www.tietosuoja.fi</span></a><span>).</span></p>
                    <h2 className="legal_h2___qfIT">Exercising rights</h2>
                    <p className="legal_p__hzARZ"><span>Requests regarding the rights of data subjects shall be made in written or in electronic form, and the request shall be addressed to JobGo using the contact details mentioned on this privacy policy.</span></p>
                    <p className="legal_p__hzARZ"><span>Identity will be checked before the information is given out, which is why we may have to ask for additional details. The request will be responded to within a reasonable time and, where possible, within one month of the request and the verification of identity.</span></p>
                    <p className="legal_p__hzARZ"><span>If the data subject’s request cannot be met, the refusal shall be communicated to the data subject in writing. We may refuse a request (for example, erasure of data) due to a statutory obligation or a statutory right of JobGo, such as an obligation or a claim relating to our services.</span></p>
                    <p className="legal_p__hzARZ"><span>The data subject may exercise the aforementioned rights by sending a written request by email or mail using the contact information provided in this privacy policy.</span></p>
                    <p className="legal_p__hzARZ"><span>If you have any questions relating to our data protection policies or wish to exercise your rights, please do not hesitate to contact us.</span></p>
                    <h2 className="legal_h2___qfIT">Changes to the privacy policy</h2>
                    <p className="legal_p__hzARZ"><span>JobGo may make changes to this privacy policy at any time by giving a notice on the website and/or by other applicable means. The data subjects are highly recommended to review the privacy policy on our website every now and then. If the data subject objects to any of the changes to this privacy policy, the data subject should cease using the services, where applicable, and they can request that we remove the personal data, unless applicable laws require us to retain such personal data. Unless stated otherwise, the then-current privacy policy applies to all personal data we process at the time.</span></p>
                    <p className="legal_p__hzARZ"><span>This privacy policy has been published on November 18, 2021, version 1.0.</span></p>
                    <p className="legal_p__hzARZ"><span>Version history:</span></p>
                    <div style={{marginBottom : 50}}>
                        <table>
                            <tbody>
                                <tr>
                                    <td><span><strong>Version history</strong></span></td>
                                    <td><span><strong>Change description</strong></span></td>
                                    <td><span><strong>Date</strong></span></td>
                                </tr>
                                <tr>
                                    <td><span>1.0</span></td>
                                    <td><span>Document created</span></td>
                                    <td><span>October 6, 2024</span></td>
                                </tr>
                                <tr>
                                    <td><span>1.1</span></td>
                                    <td><span>Improved readability; updated the list of data processors according to product changes</span></td>
                                    <td><span>October 7, 2024</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </section>
            </main>
        </>
    );
};

export default PrivacyPolicy;
