import React from "react";

const Company = () => {
    return (
        <>
            <main className="main">
                <div>
                    <div className="max-width">
                        <section className="hero_container__c3Gho">
                            <p className="hero_subtitle__5J2o7">Human and Data-driven hiring decisions with</p>
                            <h1 className="hero_title__duduZ">AI Assistants</h1>
                            <p className="hero_content__hnbt2">At Jobgo, our team of developers and recruiters is on a mission to eliminate hiring bias, creating a more efficient recruitment process with 10 x ROI.</p>
                            <div className="hero_megalogoContainer__4u_SF" style={{ height: 500 }}>
                                <div className="hero_megalogo__6ADmt">
                                    <span className="images">
                                        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: '333px', height: '1008px', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative' }}>
                                            <img alt="huge logo" srcSet="_next/static/media/logo.ae1406cb.svg 1x, _next/static/media/logo.ae1406cb.svg 2x" src="_next/static/media/logo.ae1406cb.svg" decoding="async" data-nimg="fixed" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <p className="hero_story__6C_Td">We are a European recruitment tech startup, with a diverse team from Europe and Asia united in building a global solution.</p>
                            <p className="hero_story__6C_Td">
                                Our team came together online, united by a shared mission to transform recruitment. Driven by our collective experience as recruiters, candidates, and hiring managers, we’re passionate about making hiring smarter and fairer.
                            </p>
                            <p className="hero_story__6C_Td">We’re on a mission to refocus recruitment on what truly matters-human connections and meaningful conversations.</p>
                            <p className="hero_story__6C_Td">
                                We’re on a mission to refocus recruitment on what truly matters!
                            </p>
                            <p className="hero_story__6C_Td">
                                Together with AI Assistant we take human connections,  meaningful conversations and human decision to the next level
                            </p>
                        </section>
                        <section className="tribox_container__zbDL7">
                            {/* <ul className="tribox_ul__HGxgE tribox_numbers__s9L1W">
                                <li className="tribox_li__vmsce">
                                    <div className="tribox_number__OWNya">2021</div>
                                    <div className="tribox_label__30mEk">Foundation</div>
                                </li>
                                <li className="tribox_li__vmsce">
                                    <div className="tribox_number__OWNya">04</div>
                                    <div className="tribox_label__30mEk">Team members</div>
                                </li>
                                <li className="tribox_li__vmsce">
                                    <div className="tribox_number__OWNya">03</div>
                                    <div className="tribox_label__30mEk">Nationalities</div>
                                </li>
                            </ul> */}
                            {/* <div className="tribox_linkContainer__OP9WS">
                                <a className="tribox_link__g29Cd" href="https://hireproof.notion.site/Careers-at-Hireproof-48e8594c8bb44161b1777561e9c105e7">Go to Careers</a>
                                <div className="tribox_yourenext__IHyTx">
                                    <span className="images">
                                        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                            <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27272%27%20height=%2740%27/%3e" />
                                            </span>
                                            <img srcSet="_next/static/media/yournext.f882e227.svg 1x, _next/static/media/yournext.f882e227.svg 2x" src="_next/static/media/yournext.f882e227.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                        </span>
                                    </span>
                                </div>
                            </div> */}
                            <ul className="tribox_ul__HGxgE tribox_triboxContainer__Um_FU">
                                <li className="tribox_li__vmsce tribox_tribox__VJEPx">
                                    <h3 className="tribox_triboxTitle__biXp2">Justice</h3>
                                    <span className="images">
                                        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                            <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27384%27%20height=%27286%27/%3e" />
                                            </span>
                                            <img alt="egg" srcSet="_next/static/media/tribox_1.31fed3bc.svg 1x, _next/static/media/tribox_1.31fed3bc.svg 2x" src="_next/static/media/tribox_1.31fed3bc.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                        </span>
                                    </span>
                                    <p className="tribox_triboxContent__7v4dB">Fight for equal opportunities and guide interviewers to hire based on data, not gut feeling. We want to remove bias, luck, and inconsistency from interviews.</p>
                                </li>
                                <li className="tribox_li__vmsce tribox_tribox__VJEPx">
                                    <h3 className="tribox_triboxTitle__biXp2">Be yourself</h3>
                                    <span className="images">
                                        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                            <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27384%27%20height=%27286%27/%3e" />
                                            </span>
                                            <img alt="egg" srcSet="_next/static/media/tribox_2.6b6e2ce4.svg 1x, _next/static/media/tribox_2.6b6e2ce4.svg 2x" src="_next/static/media/tribox_2.6b6e2ce4.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                        </span>
                                    </span>
                                    <p className="tribox_triboxContent__7v4dB">
                                        Culture fit is not about everybody looking and thinking the same. We value our team members for their unique ideas and worldview. Our differences are what make us strong!
                                    </p>
                                </li>
                                <li className="tribox_li__vmsce tribox_tribox__VJEPx">
                                    <h3 className="tribox_triboxTitle__biXp2">Transparency</h3>
                                    <span className="images">
                                        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                            <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27384%27%20height=%27286%27/%3e" />
                                            </span>
                                            <img alt="egg" srcSet="_next/static/media/tribox_3.2731a9de.svg 1x, _next/static/media/tribox_3.2731a9de.svg 2x" src="_next/static/media/tribox_3.2731a9de.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                        </span>
                                    </span>
                                    <p className="tribox_triboxContent__7v4dB">
                                        What you see is who we are. We don’t play games and we share our progress as well as setbacks openly. We encourage and promote total honesty in recruitment as well as our company culture.
                                    </p>
                                </li>
                            </ul>
                            <div><h2 className="tribox_bigTitle__vkwDk">All about interviewing</h2></div>
                            <ul className="tribox_ul__HGxgE tribox_triboxContainer__Um_FU">
                                <li className="tribox_li__vmsce tribox_blogBox__23Uxv tribox_blueBox__oS9vZ">
                                    <h4 className="tribox_blogBoxTitle__73kZS">The 5 cognitive biases to watch out for when hiring</h4>
                                    <div className="tribox_byLineContainer__EQHwY">
                                        <div className="tribox_byLine__EJmbd">
                                            <div className="tribox_avatarImage__jdaiU">
                                                <span className="images">
                                                    <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                                        <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                            <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2740%27%20height=%2740%27/%3e" />
                                                        </span>
                                                        <img srcSet="_next/static/media/avatar.813ad5e0.svg 1x, _next/static/media/avatar.813ad5e0.svg 2x" src="_next/static/media/avatar.813ad5e0.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="tribox_byLineContent__nfgrL">
                                                <div className="tribox_byLineName__es6gn">Max Korpinen</div>
                                                <div>9 minute read</div>
                                            </div>
                                        </div>
                                        <a className="tribox_blogLink__voUCX" href="https://blog.hireproof.io/the-5-cognitive-biases-to-watch-out-for-when-hiring-4aef074b1962">
                                            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.1261 14.4596L16.1261 10.4596C16.248 10.3377 16.3165 10.1724 16.3165 10C16.3165 9.82763 16.248 9.6623 16.1261 9.54041L12.1261 5.54041L11.2069 6.45964L14.0973 9.35002H5.6665V10.65H14.0973L11.2069 13.5404L12.1261 14.4596Z" fill="#508199" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.1261 14.4596L16.1261 10.4596C16.248 10.3377 16.3165 10.1724 16.3165 10C16.3165 9.82763 16.248 9.6623 16.1261 9.54041L12.1261 5.54041L11.2069 6.45964L14.0973 9.35002H5.6665V10.65H14.0973L11.2069 13.5404L12.1261 14.4596Z" fill="white" fillOpacity="0.94" />
                                            </svg>
                                        </a>
                                    </div>
                                </li>
                                <li className="tribox_li__vmsce tribox_blogBox__23Uxv tribox_yellowBox__Tftf2">
                                    <h4 className="tribox_blogBoxTitle__73kZS">How to measure candidate motivation in job interviews</h4>
                                    <div className="tribox_byLineContainer__EQHwY">
                                        <div className="tribox_byLine__EJmbd">
                                            <div className="tribox_avatarImage__jdaiU">
                                                <span className="images">
                                                    <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                                        <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                            <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2740%27%20height=%2740%27/%3e" />
                                                        </span>
                                                        <img srcSet="_next/static/media/avatar.813ad5e0.svg 1x, _next/static/media/avatar.813ad5e0.svg 2x" src="_next/static/media/avatar.813ad5e0.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="tribox_byLineContent__nfgrL">
                                                <div className="tribox_byLineName__es6gn">Max Korpinen</div>
                                                <div>5 minute read</div>
                                            </div>
                                        </div>
                                        <a className="tribox_blogLink__voUCX" href="https://blog.hireproof.io/how-to-measure-candidate-motivation-in-job-interviews-b72f7a9dea6c">
                                            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.1261 14.4596L16.1261 10.4596C16.248 10.3377 16.3165 10.1724 16.3165 10C16.3165 9.82763 16.248 9.6623 16.1261 9.54041L12.1261 5.54041L11.2069 6.45964L14.0973 9.35002H5.6665V10.65H14.0973L11.2069 13.5404L12.1261 14.4596Z" fill="#508199" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.1261 14.4596L16.1261 10.4596C16.248 10.3377 16.3165 10.1724 16.3165 10C16.3165 9.82763 16.248 9.6623 16.1261 9.54041L12.1261 5.54041L11.2069 6.45964L14.0973 9.35002H5.6665V10.65H14.0973L11.2069 13.5404L12.1261 14.4596Z" fill="white" fillOpacity="0.94" />
                                            </svg>
                                        </a>
                                    </div>
                                </li>
                                <li className="tribox_li__vmsce tribox_blogBox__23Uxv tribox_brownBox__LgmBF">
                                    <h4 className="tribox_blogBoxTitle__73kZS">Why you should structure your job interviews</h4>
                                    <div className="tribox_byLineContainer__EQHwY">
                                        <div className="tribox_byLine__EJmbd">
                                            <div className="tribox_avatarImage__jdaiU">
                                                <span className="images">
                                                    <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                                        <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                            <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2740%27%20height=%2740%27/%3e" />
                                                        </span>
                                                        <img srcSet="_next/static/media/avatar.813ad5e0.svg 1x, _next/static/media/avatar.813ad5e0.svg 2x" src="_next/static/media/avatar.813ad5e0.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="tribox_byLineContent__nfgrL">
                                                <div className="tribox_byLineName__es6gn">Max Korpinen</div>
                                                <div>4 minute read</div>
                                            </div>
                                        </div>
                                        <a className="tribox_blogLink__voUCX" href="https://blog.hireproof.io/why-you-should-structure-your-job-interviews-a9ea7a31faad">
                                            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.1261 14.4596L16.1261 10.4596C16.248 10.3377 16.3165 10.1724 16.3165 10C16.3165 9.82763 16.248 9.6623 16.1261 9.54041L12.1261 5.54041L11.2069 6.45964L14.0973 9.35002H5.6665V10.65H14.0973L11.2069 13.5404L12.1261 14.4596Z" fill="#508199" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.1261 14.4596L16.1261 10.4596C16.248 10.3377 16.3165 10.1724 16.3165 10C16.3165 9.82763 16.248 9.6623 16.1261 9.54041L12.1261 5.54041L11.2069 6.45964L14.0973 9.35002H5.6665V10.65H14.0973L11.2069 13.5404L12.1261 14.4596Z" fill="white" fillOpacity="0.94" />
                                            </svg>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </section>
                        <section className="half-way-cta_container__NzUQo">
                            <p className="half-way-cta_subtitle__nIHmF">Does this look easy? It is!</p>
                            <h3 className="half-way-cta_title__DmeIp">Start interviewing the right way</h3>
                            <div className="half-way-cta_ctaWrapper__j9OUf">
                                <a className="half-way-cta_button__K9GHU" href="https://app.jobgo.ai" target="_blank">Sign up for free</a>
                            </div>
                            <div className="half-way-cta_moreinfo__7PxH_">
                                Need more info?
                                <a className="half-way-cta_link__KPPq8" target="_blank" href="https://calendly.com/fredriksundell-jobgo/ai-recruitment-assistant">
                                    Get in touch
                                    <span className="images">
                                        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                            <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2711%27%20height=%2710%27/%3e" />
                                            </span>
                                            <img alt="Arrow to book demo" srcSet="_next/static/media/arrow.304cdf49.svg 1x, _next/static/media/arrow.304cdf49.svg 2x" src="_next/static/media/arrow.304cdf49.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </section>
                        <section className="big-cta_container__pgxAB" style={{ marginBottom: 20 }}>
                            <p className="big-cta_subtitle__tZT5F">Join us</p>
                            <h3 className="big-cta_title__72zAV">Check our open positions.</h3>
                            {/* <div>
                                <a className="big-cta_link__k1pPO" href="https://hireproof.notion.site/Careers-at-Hireproof-48e8594c8bb44161b1777561e9c105e7">
                                    Go to careers
                                    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.1261 14.4596L16.1261 10.4596C16.248 10.3377 16.3165 10.1724 16.3165 10C16.3165 9.82763 16.248 9.6623 16.1261 9.54041L12.1261 5.54041L11.2069 6.45964L14.0973 9.35002H5.6665V10.65H14.0973L11.2069 13.5404L12.1261 14.4596Z" fill="#508199" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.1261 14.4596L16.1261 10.4596C16.248 10.3377 16.3165 10.1724 16.3165 10C16.3165 9.82763 16.248 9.6623 16.1261 9.54041L12.1261 5.54041L11.2069 6.45964L14.0973 9.35002H5.6665V10.65H14.0973L11.2069 13.5404L12.1261 14.4596Z" fill="white" fillOpacity="0.94" />
                                    </svg>
                                </a>
                            </div> */}
                            <div className="big-cta_pipes__livsx">
                                <span className="images">
                                    <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                        <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                            <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27624%27%20height=%27580%27/%3e" />
                                        </span>
                                        <img alt="Jobgos logo" srcSet="_next/static/media/pipes.b0c3bcf3.svg 1x, _next/static/media/pipes.b0c3bcf3.svg 2x" src="_next/static/media/pipes.b0c3bcf3.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                    </span>
                                </span>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Company;