import React, { useState } from "react";

const Price = () => {

    const [activePricing, setActivePricing] = useState('yearly');

    return (
        <>
            <main className="main">
                <section>
                    <div className="max-width">
                        <div className="pricing_megalogo__1MSaZ">
                            <span className="images">
                                <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: '550px', height: '733px', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative' }}>
                                    <img alt="huge logo" srcSet="_next/static/media/megalogo.19bd1490.svg 1x, _next/static/media/megalogo.19bd1490.svg 2x" src="_next/static/media/megalogo.19bd1490.svg" decoding="async" data-nimg="fixed" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                </span>
                            </span>
                        </div>
                        <h1 className="pricing_header__1xOlM">Flexible pricing as you scale</h1>
                        <div className="pricing_selectorContainer__lcgA5">
                            <div className="pricing_tabsContainer__DwsBA">
                                <div className="pricing_saveArrow__NLFan">
                                    <span className="images">
                                        <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                            <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                                <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27204%27%20height=%27101%27/%3e" />
                                            </span>
                                            <img alt="Two months for free" srcSet="_next/static/media/save-two-months.85db4d34.svg 1x, _next/static/media/save-two-months.85db4d34.svg 2x" src="_next/static/media/save-two-months.85db4d34.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                        </span>
                                    </span>
                                </div>
                                <div className="pricing_tabs__MjGwF">
                                    <div className={`pricing_background__i_gfJ ${activePricing === 'yearly' ? 'pricing_yearly__0y2UH' : 'pricing_monthly__0y2UH'}`} />
                                    <button className="pricing_tabOption__3Tt7m" onClick={() => setActivePricing('yearly')}>Onboarding Fee</button>
                                    <button className="pricing_tabOption__3Tt7m" onClick={() => setActivePricing('monthly')}>Platform Fee</button>
                                </div>
                            </div>
                        </div>
                        <div className="pricing_pricingContainer___MSLt">
                            <div className="pricing_tier__Z5OJ3">
                                <h2 className="pricing_tierTitle__ztXY3">Pilot</h2>
                                <p className="pricing_tierOpenings__FCSj9">Individual users or small teams</p>
                                {
                                    activePricing === 'yearly' ?
                                        <>
                                            <div className="pricing_priceContainer__RyH5O">
                                                7.000 €
                                                <p className="pricing_priceSubtitle__zC9Qd"> - Onboarding and Training Including - </p>
                                            </div>
                                            <ul className="pricing_features__hcUTb">
                                                {/* <li className="pricing_feature__i_Mun">Onboarding and Training Fee</li>
                                                <li className="pricing_feature__i_Mun">5 Onboarding and AI Training Sessions</li>
                                                <li className="pricing_feature__i_Mun">One JobGo Recruitment & Direct Search Project</li> */}

                                                <li className="pricing_feature__i_Mun">6 Company Onboarding and AI Training Sessions</li>
                                                <li className="pricing_feature__i_Mun">1 Successful Recruitment & Direct Search Project</li>                                            
                                            </ul>
                                        </>
                                        :
                                        <>
                                            <div className="pricing_priceContainer__RyH5O">
                                                900 €
                                                <p className="pricing_priceSubtitle__zC9Qd"> - One Time Annual Platform Fee Including - </p>
                                            </div>
                                            <ul className="pricing_features__hcUTb">
                                                <li className="pricing_feature__i_Mun">3 Company Seats for 1 full year (Extra Seats 360 EUR/year)</li>
                                                <li className="pricing_feature__i_Mun">Unlimited Recruitments & interviews</li>
                                                <li className="pricing_feature__i_Mun">Unlimited candidates</li>
                                                <li className="pricing_feature__i_Mun">Ready to use interviewing AI Assistant</li>
                                                <li className="pricing_feature__i_Mun">Candidate Ranking</li>
                                                <li className="pricing_feature__i_Mun">Help Center / Knowledge base</li>
                                            </ul>
                                        </>
                                }
                                <div>
                                    <p className="pricing_buttonText__jmOh5" style={{ marginBottom: 15 }}>Like what you see?</p>
                                    <a className="pricing_button__cHhqh" target="_blank" href="https://app.jobgo.ai">Sign up</a>
                                </div>
                            </div>
                            <div className="pricing_tier__Z5OJ3 pricing_tierValue__0nVMe">
                                <div className="pricing_greatValue__3O_Kh">Great value</div>
                                <h2 className="pricing_tierTitle__ztXY3">Startup</h2>
                                <p className="pricing_tierOpenings__FCSj9">Hiring managers or early-stage startups</p>

                                {activePricing === 'yearly' ?
                                    <>
                                        <div className="pricing_priceContainer__RyH5O">
                                            16.000 €
                                            <p className="pricing_priceSubtitle__zC9Qd">- Onboarding and Training Including -</p>
                                        </div>
                                        <ul className="pricing_features__hcUTb">
                                            {/* <li className="pricing_feature__i_Mun">Onboarding and Training Fee </li>
                                            <li className="pricing_feature__i_Mun"> 3 JobGo Recruitment & Direct Search Projects</li>
                                            <li className="pricing_feature__i_Mun">15 Onboarding and AI Training Sessions </li> */}

                                            <li className="pricing_feature__i_Mun">18 Company Onboarding and AI Training Sessions</li>
                                            <li className="pricing_feature__i_Mun">3 Successful Recruitment & Direct Search Project</li>                                            

                                        </ul>
                                    </>
                                    :
                                    <>
                                        <div className="pricing_priceContainer__RyH5O">
                                            1.800 €
                                            <p className="pricing_priceSubtitle__zC9Qd">- One Time Annual Platform Fee Including -</p>
                                        </div>
                                        <ul className="pricing_features__hcUTb">
                                            <li className="pricing_feature__i_Mun">6 Company Seats for 1 full year (Extra Seats 360 EUR/year)</li>
                                            <li className="pricing_feature__i_Mun">Unlimited Recruitments & interviews</li>
                                            <li className="pricing_feature__i_Mun">Unlimited candidates</li>
                                            <li className="pricing_feature__i_Mun">Ready to use interviewing AI Assistant</li>
                                            <li className="pricing_feature__i_Mun">Candidate Ranking</li>
                                            <li className="pricing_feature__i_Mun">Help Center / Knowledge base</li>
                                        </ul>
                                    </>
                                }
                                <div>
                                    <p className="pricing_buttonText__jmOh5" style={{ marginBottom: 15 }}>Like what you see?</p>
                                    <a className="pricing_button__cHhqh pricing_buttonEnterprise__GQ5fI" target="_blank" href="https://app.jobgo.ai">Sign up</a>
                                </div>
                            </div>
                            <div className="pricing_tier__Z5OJ3">
                                <h2 className="pricing_tierTitle__ztXY3">Growth</h2>
                                <p className="pricing_tierOpenings__FCSj9">Scaleups with a growing hiring volume</p>

                                {
                                    activePricing === 'yearly' ?
                                        <>
                                            <div className="pricing_priceContainer__RyH5O">
                                                30.000 €
                                                <p className="pricing_priceSubtitle__zC9Qd">- Onboarding and Training Including -</p>
                                            </div>
                                            <ul className="pricing_features__hcUTb">
                                                {/* <li className="pricing_feature__i_Mun">Onboarding and Training Fee </li>
                                                <li className="pricing_feature__i_Mun">6 JobGo Recruitment & Direct Search Projects</li>
                                                <li className="pricing_feature__i_Mun">30 Onboarding and AI Training Sessions</li> */}
                                                <li className="pricing_feature__i_Mun">36 Company Onboarding and AI Training Sessions </li>
                                                <li className="pricing_feature__i_Mun">6 Successful Recruitment & Direct Search Project
                                                </li>
                                            </ul>
                                        </>
                                        :
                                        <>
                                            <div className="pricing_priceContainer__RyH5O">
                                                3.600 €
                                                <p className="pricing_priceSubtitle__zC9Qd">- One Time Annual Platform Fee Including -</p>
                                            </div>
                                            <ul className="pricing_features__hcUTb">
                                                <li className="pricing_feature__i_Mun">12 Company Seats for 1 full year (Extra Seat for 360 EUR/year)</li>
                                                <li className="pricing_feature__i_Mun">Unlimited Recruitments & interviews</li>
                                                <li className="pricing_feature__i_Mun">Unlimited candidates</li>
                                                <li className="pricing_feature__i_Mun">Ready to use interviewing AI Assistant</li>
                                                <li className="pricing_feature__i_Mun">Candidate Ranking</li>
                                                <li className="pricing_feature__i_Mun">Help Center / Knowledge base</li>
                                            </ul>
                                        </>
                                }
                                <div>
                                    <p className="pricing_buttonText__jmOh5">Almost ready</p>
                                    <a href="https://calendly.com/fredriksundell-jobgo/ai-recruitment-assistant" target="_blank" rel="noreferrer"><button className="pricing_button__cHhqh">Talk to us</button></a>
                                </div>
                            </div>
                            <div className="pricing_tier__Z5OJ3">
                                <h2 className="pricing_tierTitle__ztXY3">Enterprise</h2>
                                <p className="pricing_tierOpenings__FCSj9">Global companies with a high hiring volume</p>
                                <div className="pricing_priceContainer__RyH5O">
                                    <a href="https://calendly.com/fredriksundell-jobgo/ai-recruitment-assistant" target="_blank" rel="noreferrer" className="pricing_talkToUs__gSAtl">Talk to us</a>
                                    <p className="pricing_priceSubtitle__zC9Qd">We will figure it out</p>
                                </div>
                                <ul className="pricing_features__hcUTb">
                                    <li className="pricing_feature__i_Mun">Unlimited job openings</li>
                                    <li className="pricing_feature__i_Mun">Unlimited interviews</li>
                                    <li className="pricing_feature__i_Mun">Unlimited candidates</li>
                                    <li className="pricing_feature__i_Mun">Unlimited interviewers</li>
                                    <li className="pricing_feature__i_Mun">Ready to use interview templates</li>
                                    <li className="pricing_feature__i_Mun">In depth candidate comparison</li>
                                    <li className="pricing_feature__i_Mun">Single sign-on (SSO)</li>
                                    <li className="pricing_feature__i_Mun">Help Center / Knowledge base</li>
                                    <li className="pricing_feature__i_Mun">Panel interviewing</li>
                                    <li className="pricing_feature__i_Mun">Organization / Multi-user platform</li>
                                    <li className="pricing_feature__i_Mun">Integrations</li>
                                    <li className="pricing_feature__i_Mun">Dedicated Customer Support / CSM</li>
                                </ul>
                                <div>
                                    <p className="pricing_buttonText__jmOh5">Almost ready</p>
                                    <a href="https://calendly.com/fredriksundell-jobgo/ai-recruitment-assistant" target="_blank" rel="noreferrer"><button className="pricing_button__cHhqh">Talk to us</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pricing_endCtaContainer__dBSpN">
                        <p className="pricing_subtitle__4OPlD">Still have some questions?</p>
                        <h3 className="pricing_title__2FZ14">Book a demo, and start hiring better</h3>
                        <div className="pricing_ctaWrapper__tQ_BW">
                            <a href="https://calendly.com/fredriksundell-jobgo/ai-recruitment-assistant" target="_blank" rel="noreferrer"><button className="pricing_button__cHhqh">Book a demo</button></a>
                        </div>
                        <div className="pricing_moreinfo__mmMMB">
                            Just want to say hi?
                            <a className="pricing_link__ZCjkP" target="_blank" href="https://calendly.com/fredriksundell-jobgo/ai-recruitment-assistant">
                                Get in touch
                                <span className="images">
                                    <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
                                        <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                                            <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2711%27%20height=%2710%27/%3e" />
                                        </span>
                                        <img alt="Arrow to book demo" srcSet="_next/static/media/arrow.304cdf49.svg 1x, _next/static/media/arrow.304cdf49.svg 2x" src="_next/static/media/arrow.304cdf49.svg" decoding="async" data-nimg="intrinsic" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }} />
                                    </span>
                                </span>
                            </a>
                        </div>
                    </section>
                </section>
            </main>
        </>
    )
}

export default Price;